import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout, { Seo } from '../../../components/Layout';
import Cover from '../../../components/Elements/Cover';
import PageSection from '../../../components/Elements/PageSection';
import Row, { Col } from '../../../components/Elements/Grid';

import Employee from '../../../components/Pages/Team/Employee';
import GetAJob from '../../../components/Parts/GetAJob';
import Image from '../../../components/Elements/Image';
import withTranslations from '../../../i18n/withTranslations';

const Page = () => {
  const { t } = useTranslation('overOnsTeam');

  return (
    <Layout className="team-grid">
      <Seo title={t('seoTitle')} />
      <Cover image="avocado" nowrap>
        <Image
          image="headerTeam"
          className="cover-photo"
          style={{ position: 'fixed' }}
          imgStyle={{ objectPosition: 'center 80%' }}
        />
        <div className="wrapped">
          <PageSection center>
            <h1>{t('title')}</h1>
            <p>{t('description.p1')}</p>
            <p>{t('description.p2')}</p>
            <p>{t('description.p3')}</p>
            <p>{t('description.p4')}</p>
            <p>{t('description.p5')}</p>
          </PageSection>
          <PageSection grid className="photos">
            <h2 style={{ textAlign: 'center' }}>{t('colleagues')}</h2>
            <Row>
              <Col>
                <Employee name="robin" icon="🧙🏻‍♂️" />
              </Col>
              <Col>
                <Employee name="jesper" icon="🔋️" />
              </Col>
              <Col>
                <Employee name="robbin" icon="🤷🏻‍♂️" />
              </Col>
              <Col>
                <Employee name="sven" icon="🥽" />
              </Col>
              <Col>
                <Employee name="floris" icon="🤘🏻" />
              </Col>
              <Col>
                <Employee name="tom" icon="🎧" />
              </Col>
              <Col>
                <Employee name="tristan" icon="☕️" />
              </Col>
              <Col>
                <Employee name="andreas" icon="🍪️" />
              </Col>
              <Col>
                <Employee name="ramon" icon="👌🏻" />
              </Col>
              <Col>
                <Employee name="henk" icon="👨‍👩‍👧‍👦" />
              </Col>
              <Col>
                <Employee name="marees" icon="🧚🏼‍♀️️" />
              </Col>
              <Col>
                <Employee name="boudewijn" icon="🧱" />
              </Col>
              <Col>
                <Employee name="sietze" icon="🏍" />
              </Col>
              <Col>
                <Employee name="marty" icon="🕺🏻" />
              </Col>
              <Col>
                <Employee name="eelco" icon="🤘🏻" />
              </Col>
              <Col>
                <Employee name="patrick" icon="🏍" />
              </Col>
              <Col>
                <Employee name="brian" icon="👾" />
              </Col>
              <Col>
                <Employee name="len" icon="🎶" />
              </Col>
              <Col>
                <Employee name="glenn" icon="🎼" />
              </Col>
              <Col>
                <Employee name="michel" icon="🏍" />
              </Col>
              <Col>
                <Employee name="bart" icon="📻" />
              </Col>
              <Col>
                <Employee name="peter" icon="🗿" />
              </Col>
              <Col>
                <Employee name="roline" icon="🌻" />
              </Col>
              <Col>
                <Employee name="yannick" icon="😸" />
              </Col>
              <Col>
                <Employee name="joris" icon="🏃🏻" />
              </Col>
              <Col>
                <Employee name="jari" icon="🇦🇼" />
              </Col>
              <Col>
                <Employee name="jake" icon="🍟" />
              </Col>
              <Col>
                <Employee name="lorenzo" icon="🏋🏻‍♀️" />
              </Col>
              <Col>
                <Employee name="jasper" icon="🪴" />
              </Col>
              <Col>
                <Employee name="daan" icon="😎" />
              </Col>
            </Row>
          </PageSection>
          <GetAJob title={t('getAJobTitle')} image="footerAreYouNext" />
        </div>
      </Cover>
    </Layout>
  );
};

export default withTranslations(Page);
